import clsx from 'clsx';

export const Bounded = ({
  as: Comp = 'div',
  yPadding = 'base',
  className,
  index,
  children
}) => {
  return (
    <Comp
      className={clsx(
        'border-bottom',
        index % 2 && 'bg-white',
        yPadding === 'xs' && 'py-2',
        yPadding === 'sm' && 'py-3',
        yPadding === 'base' && 'py-5',
        className
      )}>
      <div className="container">{children}</div>
    </Comp>
  );
};
