import { PrismicLink } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';
import clsx from 'clsx';
import * as prismicH from '@prismicio/helpers';

import { RichText } from '../../components/RichText';
import { BaseSlice } from '../../components/BaseSlice';
import { Bounded } from '../../components/Bounded';

const TextWithImage = ({ slice, index }) => {
  // TODO we currently have two ways to do this, let's pick one
  const alignImageRight =
    slice.variation === 'rightAlignImage' || slice.primary.alignImageRight;

  return (
    <Bounded as="section" index={index}>
      <BaseSlice
        className={clsx(
          'row',
          alignImageRight && 'flex-row-reverse',
          slice.variation === 'imageBelowText' && 'flex-column-reverse'
        )}>
        <div
          className={clsx(
            slice.variation === 'imageBelowText'
              ? 'col-lg-12 mt-sm-5 mt-4'
              : 'col-lg-6 col-md-12'
          )}>
          {prismicH.isFilled.image(slice.primary.image) && (
            <div
              className={clsx(
                slice.variation !== 'imageBelowText' && 'px-lg-5'
              )}>
              <PrismicNextImage
                field={slice.primary.image}
                alt={slice.primary.image.alt}
                sizes="100vw"
                className="img-fluid rounded mb-4"
              />
            </div>
          )}
        </div>
        <div
          className={clsx(
            slice.variation === 'imageBelowText'
              ? 'col-lg-10'
              : 'col-lg-6 col-md-12'
          )}>
          <RichText field={slice.primary.text} />
          {prismicH.isFilled.link(slice.primary.buttonLink) &&
            prismicH.isFilled.keyText(slice.primary.buttonText) && (
              <PrismicLink
                field={slice.primary.buttonLink}
                className="btn btn-primary px-4">
                {slice.primary.buttonText}
              </PrismicLink>
            )}
        </div>
      </BaseSlice>
    </Bounded>
  );
};

export default TextWithImage;
